import request from './request'
const wechatAPI = 'wechat'

const url = {
  scanAddUser: `${wechatAPI}/wechat/scanAddUser`, // 扫描签约
  mineInfo: `${wechatAPI}/wechat/mineInfo`, // 我的信息
  findIdCard: `${wechatAPI}/wechat/findIdCard`, // 查询是否需要上传身份证信息
  mineProject: `${wechatAPI}/wechat/mineProject`, // 我的项目
  settList: `${wechatAPI}/wechat/settList`, // 结算记录
  findProjects: `${wechatAPI}/wechat/findProjects`, // 查询需上传交付物的项目
  getOneDeliveryFile: `${wechatAPI}/wechat/getOneDeliveryFile`, // 结算记录详情
  addDeliveryFile: `${wechatAPI}/wechat/addDeliveryFile`, // 上传项目交付物
  addDeliveryFileToFreelancer: `${wechatAPI}/wechat/addDeliveryFileToFreelancer`, // 上传个人结算交付物
  addMessageVO: 'message/messageInfo/addMessageVO', // 短信验证
  projectList: `${wechatAPI}/wechat/projectList`, // 项目广场列表
  getOneProjectById: `${wechatAPI}/wechat/getOneProjectById`, // 项目广场列表详情
  signUpProject: `${wechatAPI}/wechat/signUpProject`, // 项目广场列表详情
  sendCode: `${wechatAPI}/wechat/index`, // 发送code给后台
  picZip: `${wechatAPI}/wechat/picZip`, // 上传
  uploadPic: 'system/upload/pic', // 上传
  initLive: `${wechatAPI}/wechat/initLive`, // 获取活体认证的数字
  submitVedio: `${wechatAPI}/wechat/submitVedio`, // 个人活体提交视频
  queryLevyBodyInfos: `${wechatAPI}/wechat/queryLevyBodyInfos`, // 获取代征主体
  queryDetailByIdCard: `${wechatAPI}/wechat/queryDetailByIdCard`, // 获取申报明细
  getHaoZhaoToken: `${wechatAPI}/wechat/getHaoZhaoToken`, // 获取好招token
  uploadFile: `${wechatAPI}/wechat/uploadFile`, // 上传给好招身份证
  isOpenOnHaozhao: `${wechatAPI}/wechat/isOpenOnHaozhao` // 查询是否签约好招
}

const requestHTAPI = {
  scanAddUser: params => {
    return request.postJson(url.scanAddUser, params)
  },
  addMessageVO: params => {
    return request.postJson(url.addMessageVO, params)
  },
  mineInfo: params => {
    return request.postJson(url.mineInfo, params)
  },
  findIdCard: params => {
    return request.postJson(url.findIdCard, params)
  },
  projectList: params => {
    return request.postJson(url.projectList, params)
  },
  getOneProjectById: params => {
    return request.postJson(url.getOneProjectById, params)
  },
  signUpProject: params => {
    return request.postJson(url.signUpProject, params)
  },
  mineProject: params => {
    return request.postJson(url.mineProject, params)
  },
  settList: params => {
    return request.postJson(url.settList, params)
  },
  findProjects: params => {
    return request.postJson(url.findProjects, params)
  },
  getOneDeliveryFile: params => {
    return request.postJson(url.getOneDeliveryFile, params)
  },
  addDeliveryFile: params => {
    return request.postJson(url.addDeliveryFile, params)
  },
  addDeliveryFileToFreelancer: params => {
    return request.postJson(url.addDeliveryFileToFreelancer, params)
  },
  sendCode: params => {
    return request.postJson(url.sendCode, params)
  },
  picZip: params => {
    return request.postJson(url.picZip, params)
  },
  uploadPic: params => {
    return request.postJson(url.uploadPic, params)
  },
  initLive: params => {
    return request.postJson(url.initLive, params)
  },
  submitVedio: params => {
    return request.postJson(url.submitVedio, params)
  },
  queryLevyBodyInfos: params => {
    return request.postJson(url.queryLevyBodyInfos, params)
  },
  queryDetailByIdCard: params => {
    return request.postJson(url.queryDetailByIdCard, params)
  },
  getHaoZhaoToken: params => {
    return request.postJson(url.getHaoZhaoToken, params)
  },
  uploadFile: params => {
    return request.postJson(url.uploadFile, params)
  },
  isOpenOnHaozhao: params => {
    return request.postJson(url.isOpenOnHaozhao, params)
  }
}

export default requestHTAPI
